<template>
  <div class="space-y-4">
    <asom-alert v-if="error" variant="error" :error-message="error" />
    <asom-card 
      ><form class="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
        <asom-form-field
          :label="'Body Parts Injured'"
          :state="inputStates('formData.bodyParts')"
          error="Body Parts Injured is required"
          required
        >
          <asom-input-text
            v-model="formData.bodyParts"
            :state="inputStates('formData.bodyParts')"
          />
        </asom-form-field>
        <asom-form-field
          :label="'Treatment Rendered'"
          :state="inputStates('formData.renderedTreatment')"
          error="Treatment Rendered is required"
          required
        >
          <asom-input-text
            v-model="formData.renderedTreatment"
            :state="inputStates('formData.renderedTreatment')"
          />
        </asom-form-field>
        <asom-form-field
          label="Remarks"
          required
          :state="inputStates('formData.remarks')"
          error="Remarks is required"
        >
          <asom-input-textarea
            type="text"
            v-model="formData.remarks"
            :state="inputStates('formData.remarks')"
            :maxlength="1000"
          />
        </asom-form-field>
        <asom-form-field
          class="col-span-2"
          label="Injury Diagram"
          required
          :state="inputStates('formData.injuryDiagram')"
          error="Injury Diagram is required"
        >
          <asom-human-diagram-input v-model="formData.injuryDiagram" />
        </asom-form-field>
        <div class="col-span-2 pt-4" v-if="formData.items.length > 0">
          <article class="bg-gray-100 rounded-md pl-4 pr-4 pb-4">
            <div
              v-for="(item, index) in formData.items"
              :key="index"
              class="pt-4"
            >
              <asom-card :title="item.inventoryTypeName">
                <template v-slot:header>
                  <div class="flex justify-between">
                    <h3 class="text-lg leading-6 font-medium text-gray-900">
                      {{ item.inventoryTypeName }}
                    </h3>
                    <div>
                      <button
                        type="button"
                        @click="removeItem(item.inventoryId)"
                      >
                        <asom-icon icon="close"></asom-icon>
                      </button>
                    </div>
                  </div>
                </template>
                <div class="space-y-4">
                  <div class="grid sm:grid-cols-1 md:grid-cols-3 gap-4">
                    <asom-form-field label="Quantity">
                      <span class="mt-3">{{ get(item, "quantity", "-") }}</span>
                    </asom-form-field>
                    <asom-form-field label="First Aid Box">
                      <span class="mt-3">{{
                        get(item, "firstAidBoxName", "-")
                      }}</span>
                    </asom-form-field>
                  </div>
                </div>
              </asom-card>
            </div>
          </article>
        </div>
        <div class="col-span-2 flex justify-end space-x-4 pt-8">
          <asom-button
            text="Back"
            variant="secondary"
            @click="$router.go(-1)"
          />
          <asom-button
            text="Add Item"
            @click="addItemClicked"
            :loading="isSubmitting"
          />
          <asom-button
            text="Use Items"
            @click="confirmClicked"
            :loading="isSubmitting"
          />
        </div></form
    ></asom-card>
    <item-selection v-model="showAddItem" @selectedItems="addedItems($event)" />
  </div>
</template>

<script>
import get from "lodash.get";
import remove from "lodash.remove";
import some from "lodash.some";
import find from "lodash.find";
import findIndex from "lodash.findindex";
import { displayUtcDate } from "@/helpers/dateTimeHelpers";
import { mapGetters } from "vuex";
import { required } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import { createFirstAidForm } from "../../../../services/inventory.service";
import ItemSelection from "./ItemSelection";
import { add } from "../../../../helpers/numbers";
import { imageDataToBlob } from "../../../../helpers/imageDataToBlob";
import { uploadFile } from "../../../../services/file.service";
import { ATTACHMENT_CATEGORIES } from "@/constants/APIEnums/attachmentEnums";

export default {
  components: {
    ItemSelection,
  },
  setup: () => ({ v$: useVuelidate() }),
  mixins: [inputStates],
  data() {
    return {
      error: null,
      isSubmitting: null,
      formData: {
        bodyParts: null,
        renderedTreatment: null,
        injuryDiagram: null,
        injuryDiagramId: null,
        items: [],
        remarks: null,
      },
      showAddItem: false,
    };
  },
  computed: {
    ...mapGetters({
      stationId: "selectedStation/id",
      lineId: "selectedStation/lineId",
    }),
  },
  validations() {
    let validations = {
      formData: {
        bodyParts: { required },
        renderedTreatment: { required },
        remarks: { required },
        injuryDiagram: { required },
      },
    };
    return validations;
  },
  methods: {
    get,
    formatDate(date) {
      if (date !== "-") return displayUtcDate(date);
      else return date;
    },
    removeItem(itemId) {
      remove(this.formData.items, { inventoryId: itemId });
    },
    addItemClicked() {
      this.error = null;
      this.showAddItem = true;
    },
    addedItems(items) {
      items.forEach((item) => {
        item.quantity = parseInt(item.quantity);
        this.addToList(item);
      });
    },
    addToList(item) {
      if (this.formData.items.length > 0) {
        let isExistingItem = some(this.formData.items, {
          inventoryId: get(item, "inventoryId", null),
        });
        if (isExistingItem) {
          var existingItemQuantity = find(this.formData.items, {
            inventoryId: get(item, "inventoryId", null),
          }).quantity;
          let newQuantity = add(existingItemQuantity, item.quantity);
          let index = findIndex(this.formData.items, {
            inventoryId: get(item, "inventoryId", null),
          });
          this.formData.items[index].quantity = newQuantity;
        } else this.formData.items.push(item);
      } else {
        this.formData.items.push(item);
      }
    },
    confirmClicked() {
      if (this.isSubmitting) return;
      this.error = "";
      this.v$.formData.$reset();
      this.v$.formData.$touch();
      if (!this.v$.formData.$invalid) {
        if (this.formData.items.length > 0) this.onSubmit();
        else {
          this.error = "Please Add Items";
          this.$scrollTop();
        }
      } else {
        this.error = "Please complete all required fields";
        this.$scrollTop();
      }
    },
    async onSubmit() {
      this.isSubmitting = true;
      const isInjuryDiagramUploaded = this.formData.injuryDiagramId !== null;
      if (!isInjuryDiagramUploaded) {
        const signatureBlob = imageDataToBlob(
          this.formData.injuryDiagram,
          "bagger_signature"
        );
        const uploadResult = await uploadFile([signatureBlob], ATTACHMENT_CATEGORIES.INVENTORY);
        if (!uploadResult.success) {
          this.error = uploadResult.payload;
          this.isSubmitting = false;
          this.$scrollTop();
          return;
        }
        this.formData.injuryDiagramId = get(
          uploadResult,
          "payload.files.0.fileId"
        );
      }
      const result = await createFirstAidForm({
        bodyParts: this.formData.bodyParts,
        renderedTreatment: this.formData.renderedTreatment,
        stationId: this.stationId,
        lineId: this.lineId,
        remarks: this.formData.remarks,
        firstAidInventories: this.formData.items,
        attachmentId: this.formData.injuryDiagramId,
      });
      if (result.success) {
        this.isSubmitting = false;
        this.$router.go(-1);
      } else {
        this.isSubmitting = false;
        this.error = result.payload;
        this.$scrollTop();
      }
    },
  },
};
</script>
