<template>
  <asom-modal
    v-model="showItemSelection"
    title="Select Items"
    :dismissible="false"
    size="lg"
  >
    <asom-alert v-if="error" :error-message="error" />
    <asom-form-field
      :label="'First Aid Box'"
      required
      error="First Aid Box is required"
      class="w-72"
    >
      <asom-input-select
        v-model="formData.firstAidBox"
        :loading="isLoadingFirstAidBoxes"
        :options="firstAidBoxOptions"
        @search-change="queryFirstAidBoxes"
        ref="firstAidBoxSelection"
        placeholder="Type to search for First Aid Box"
      />
    </asom-form-field>
    <div v-if="!isLoadingFirstAidBoxItems" class="pt-6">
      <asom-client-table
        v-if="inventoryTypes.length > 0"
        :columns="tableData.columns"
        :data="tableData.data"
        :filterable="false"
        :pagination="false"
      >
        <template v-slot:header_amount>
          Available Quantity
        </template>
        <template v-slot:header_select>
          <input type="checkbox" v-model="multiSelectAll" @change="onSelect" />
        </template>
        <template v-slot:select="scopedSlots">
          <input
            class="mx-2"
            type="checkbox"
            v-model="scopedSlots.rowData.select"
            @change="onSelect"
          />
        </template>
        <template v-slot:expiryDate="scopedSlots">
          {{scopedSlots.data ? displayUtcDate(scopedSlots.data) : "NA"}}
        </template>
        <template v-slot:quantity="scopedSlots">
          <asom-form-field required class="w-32">
            <asom-input-text
              type="number"
              min="0"
              v-model="scopedSlots.rowData.quantity"
          /></asom-form-field>
        </template>
      </asom-client-table>
    </div>
    <div v-else class="text-center">
      <asom-icon icon="spinner" class="animate-spin" />
    </div>
    <div class="flex flex-row-reverse pt-4 gap-4">
      <asom-button
        @click="onSubmit"
        :disabled="!selectedItems.length > 0"
        :loading="isLoadingFirstAidBoxItems"
        text="Confirm"
      />
      <asom-button
        @click="toggleModal(false)"
        text="Cancel"
        variant="secondary"
      />
    </div>
  </asom-modal>
</template>

<script>
import get from "lodash.get";
import { mapGetters } from "vuex";
import { useVuelidate } from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import {
  getListOfFirstAidBoxes,
  getFirstAidBoxDetails,
} from "../../../../services/inventory.service";
import { displayUtcDate } from "@/helpers/dateTimeHelpers";

export default {
  setup: () => ({ v$: useVuelidate() }),
  props: {
    modelValue: {
      type: Boolean,
    },
  },
  emits: ["update:modelValue", "selectedItems"],
  data() {
    return {
      formData: {
        firstAidBox: null,
      },
      firstAidBoxOptions: [],
      isLoadingFirstAidBoxes: false,
      isLoadingFirstAidBoxItems: false,
      error: null,
      disableConfirmation: false,
      inventoryTypes: [],
      selectedItems: [],
    };
  },
  validations: {
    selectedItems: {
      required,
      minLength: minLength(1),
      $each: {
        quantity: {
          required,
        },
      },
    },
  },
  computed: {
    ...mapGetters({
      stationId: "selectedStation/id",
      lineId: "auth/userLineId",
    }),
    showItemSelection: {
      get() {
        return this.modelValue;
      },
      set(newValue) {
        this.$emit("update:modelValue", newValue);
      },
    },
    tableData() {
      return {
        columns: ["select", "inventoryTypeName", "amount", "expiryDate", "quantity"],
        data: this.inventoryTypes,
      };
    },
    multiSelectAll: {
      get() {
        return this.inventoryTypes.every((transaction) => transaction.select);
      },
      set(newValue) {
        this.inventoryTypes = this.inventoryTypes.map((_type) => ({
          ..._type,
          select: newValue,
        }));
      },
    },
  },
  mounted() {
    this.queryFirstAidBoxes("", false);
  },
  watch: {
    "formData.firstAidBox": function(newValue) {
      if (newValue) this.getItemsInBox(get(newValue, "value"));
    },
  },
  methods: {
    get,
    displayUtcDate,
    toggleModal(value) {
      this.$emit("update:modelValue", value);
      this.formData.firstAidBox = null;
      this.inventoryTypes = [];
      this.selectedItems = [];
      // this.$refs.firstAidBoxSelection.clearDropdownOptions();
    },
    onSelect() {
      const newValue = [];
      this.$nextTick(() => {
        this.tableData.data.forEach((item) => {
          if (item.select) {
            newValue.push(item);
          }
        });
        this.selectedItems = newValue;
      });
    },
    onSubmit() {
      this.error = "";
      this.v$.$reset();
      this.v$.$touch();
      if (!this.v$.$invalid && this.quantityValidation()) {
        var items = this.selectedItems;
        this.$emit("selectedItems", items);
        this.toggleModal(false);
      } else {
        this.error = "Please complete all required fields";
        this.$scrollTop();
      }
    },
    quantityValidation() {
      let quantityAvailable;
      this.selectedItems.every((item) => {
        quantityAvailable = get(item, "quantity", null);
        if (quantityAvailable == null) return false;
      });
      if (quantityAvailable == null) return false;
      else return true;
    },
    async getItemsInBox(FirstAidBoxId) {
      this.error = null;
      this.isLoadingFirstAidBoxItems = true;
      const result = await getFirstAidBoxDetails({
        firstAidBoxId: FirstAidBoxId,
      });
      if (
        result.success &&
        Array.isArray(get(result, "payload.firstAidInventories"))
      ) {
        this.isLoadingFirstAidBoxItems = false;
        this.inventoryTypes = get(result, "payload.firstAidInventories", []);
      } else {
        this.isLoadingFirstAidBoxItems = false;
        this.error = result.payload;
        return [];
      }
    },
    async queryFirstAidBoxes(query, allowEmpty = false) {
      this.error = null;
      this.isLoadingFirstAidBoxes = true;
      if (!query && !allowEmpty) {
        this.isLoadingFirstAidBoxes = false;
        this.firstAidBoxOptions = [];
      }
      const result = await getListOfFirstAidBoxes({
        lineId: this.lineId,
        stationId: this.stationId,
        search: query.trim(),
      });
      if (result.success && Array.isArray(get(result, "payload.list"))) {
        this.isLoadingFirstAidBoxes = false;
        this.error = null;
        this.firstAidBoxOptions = get(result, "payload.list", []).map(
          ({ inventoryFirstAidBoxId, name }) => ({
            label: name,
            value: inventoryFirstAidBoxId,
          })
        );
      } else {
        this.isLoadingFirstAidBoxes = false;
        this.error = result.payload;
        this.firstAidBoxOptions = [];
      }
    },
  },
};
</script>
